<template>
  <div>
    <b-skeleton-wrapper :loading="spinner">
      <template #loading>
        <b-card>
          <b-skeleton-table
            :rows="5"
            :columns="5"
            :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
          />
        </b-card>
      </template>
      <b-table
        responsive
        outlined
        hover
        selectable
        :fields="fields"
        :items="items"
        show-empty
        empty-text="No matching records found"
        :select-mode="selectMode"
        @row-selected="onRowSelected"
      >

        <template #cell(device_name)="data">
          <span class="font-weight-bold text-primary">
            {{ data.item.device_name }}
          </span>
        </template>
        <template #cell(group_name)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.group_name }}
          </span>
          <!-- <small class="text-muted">{{ data.item.group.email }}</small> -->

        </template>
        <template #cell(total_revenue)="data">
          <div class="text-nowrap">
            <span>
              $ {{ data.item.total_revenue ? data.item.total_revenue :'0' }}
            </span>
          </div>
        </template>
      </b-table>
      <div
        v-if="deviceListData.length!==0"
      >
        <CustomPagination
          v-if="deviceListData.length"
          class=" my-2"
          :total="paginationObject.total"
          :page="paginationObject.offset"
          :per-page="paginationObject.limit"
          @perPageChanged="perPageChanged"
          @pageChanged="pageChanged"
        />
      </div>
    </b-skeleton-wrapper>
  </div>
</template>
<script>
import {
  BTable, BSkeletonTable, BSkeletonWrapper, BCard,
} from 'bootstrap-vue'
import CustomPagination from '@/components/common/CustomPagination.vue'

export default {
  components: {
    BTable,
    BSkeletonTable,
    BSkeletonWrapper,
    CustomPagination,
    BCard,
  },
  props: {
    spinner: {
      type: Boolean,
      required: false,
    },
    deviceListData: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'device_name',
          label: 'SCREEN NAME',
          sortable: false,
        },
        {
          key: 'group_name',
          label: 'BUSINESS NAME',
          sortable: false,
        },
        {
          key: 'total_revenue',
          label: 'REVENUE',
          sortable: false,
        },
      ],
      items: [],
      selectMode: 'single',
      selected: [],
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    paginationObject() {
      return {
        offset: this.$store.getters['reports/getOffset'],
        total: this.$store.getters['reports/getTotal'],
        limit: this.$store.getters['reports/getLimit'],
      }
    },
  },
  watch: {
    deviceListData: {
      handler(newData) {
        this.items = newData
        this.paginationObject.total = newData.length
      },
      immediate: true, // Trigger the handler immediately on component mount
    },
  },
  methods: {
    perPageChanged(limit) {
      this.$store.dispatch('reports/setPaginatedDeviceData', { offset: 1, limit })
    },
    pageChanged(offset) {
      this.$store.dispatch('reports/setPaginatedDeviceData', { offset, limit: this.paginationObject.limit })
    },
    onRowSelected(group) {
      // eslint-disable-next-line prefer-destructuring
      this.selected = group[0]
      const id = this.selected.device_id
      const name = this.selected.device_name
      const groupId = this.selected.group_id
      if (this.currentUser.user_has_role[0].role.role === 'admin') {
        this.$router.push({ name: 'single-table-admin', params: { id, name }, query: { groupId } })
      } else {
        this.$router.push({ name: 'single-table-user', params: { id, name }, query: { groupId } })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.badge{
  border-radius: 25px;
  padding-inline: .625rem;
}
</style>

<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="total-revenue-card"
        >
          <b-row
            align-h="between"
            class="py-2 pl-1"
            no-gutters
          >
            <b-col
              lg="3"
              xl="3"
              md="3"
              sm="12"
              class="title"
            >
              <h4 class="mb-0">
                <span class="font-weight-bold total-revenue"> {{ 'Total Revenue $ '+ (totalRevenue ? totalRevenue :"0") }}</span>
              </h4>
            </b-col>
            <b-col
              xl="7"
              lg="7"
              md="12"
              sm="12"
              class="dropdown-div"
            >
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="dropdown-class"
              >
                <template #button-content>
                  {{ groupName ? groupName: 'Select Business' }}
                </template>
                <b-dropdown-item
                  v-if="groups.length>0 && userRole === AccountTypes.ADMIN"
                  @click="setGroupsResettingDeviceAndSsp('All Businesses')"
                >
                  All
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(group, index) in groups"
                  :key="index"
                  @click="afterSelectGroup(group)"
                >
                  <span>
                    {{ userRole === AccountTypes.ADMIN ? group.name : group.user_has_group.name }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="dropdown-class"
                :disabled="devicesDisabled"
              >
                <template #button-content>
                  {{ deviceName ? deviceName : 'Select Screen' }}
                </template>

                <b-dropdown-item v-if="devices.length === 0">
                  <span>No Screens</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="devices.length>0"
                  @click="setDevicesAndResettingSsp()"
                >
                  All
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(device, index) in devices"
                  :key="index"
                  @click="selectDevice(device)"
                >
                  <span>
                    {{ device.name }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                v-if="userRole===AccountTypes.ADMIN"
                :text="ssp ? ssp: 'Select SSP'"
                variant="outline-primary"
                class="dropdown-class"
                :disabled="sspDisabled"
              >
                <b-dropdown-item
                  @click="ssp='both'"
                >
                  Both SSP
                </b-dropdown-item>
                <b-dropdown-item @click="ssp= 'hivestack'">
                  Hivestack
                </b-dropdown-item>
                <b-dropdown-item @click="ssp= 'vistar'">
                  Vistar
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col
              xl="2"
              lg="2"
              md="12"
              sm="12"
              class="title"
            >
              <b-button
                variant="primary"
                class="mx-1"
                style="float: right;"
                :disabled="disabled"
                @click="getAnalytics"
              >
                <div
                  v-if="spinner"
                  class="spinner"
                >
                  <b-spinner
                    small
                  />
                  <span class="sr-only">Loading...</span>
                </div>
                Search
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <ReportChart
          :load-data="spinner"
          @get-filters="setFilter"
          @get-month="setMonth"
          @get-year="setYear"
        />
      </b-col>
      <!--Ad Report Table-->
      <b-col
        lg="12"
        xl="12"
        md="12"
      >
        <AdReportTable
          :spinner="spinner"
          :device-list-data="deviceListsData"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AccountTypes from '@/common/enums/accountTypeEnum'
import ReportChart from './ReportChart.vue'
import AdReportTable from '@/components/ad-reporting/AdReportTable.vue'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    ReportChart,
    BSpinner,
    AdReportTable,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      spinner: false,
      AccountTypes,
      groupName: '',
      groupId: '',
      deviceId: '',
      deviceName: '',
      ssp: '',
      month: '',
      year: '',
      dateFilter: '',
      dataSet: [],
      labels: [],
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    groups() {
      return this.$store.getters['reports/getAllGroups']
    },
    totalRevenue() {
      return this.$store.getters['reports/getDevicesTotalRevenue']
    },
    devices() {
      return this.$store.getters['reports/getDevices']
    },
    deviceListsData() {
      return this.$store.getters['reports/getDeviceList']
    },
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    devicesDisabled() {
      return this.groupName === '' || this.groupName === 'All Businesses'
    },
    sspDisabled() {
      if (
        (this.groupName !== ''
        && this.deviceName !== '') || this.groupName === 'All Businesses'
      ) {
        return false
      }
      return true
    },
    disabled() {
      if (
        (this.groupName !== '' && this.deviceName !== '' && this.ssp !== '')
        || (this.groupName === 'All Businesses' && this.ssp !== '')
      ) {
        return false
      } if (this.groupName !== '' && this.deviceName !== '' && this.userRole === this.AccountTypes.USER) {
        return false
      }
      return true
    },
  },
  async mounted() {
    await this.$store.dispatch('reports/allGroups')
  },
  methods: {
    afterSelectGroup(group) {
      this.ssp = ''
      this.deviceName = ''
      this.deviceId = ''
      if (this.currentUser.user_has_role[0].role.role === 'admin') {
        this.groupName = group.name
        this.groupId = group.id
        this.$store.dispatch('reports/getDevices', { userHasGroupId: group.id })
      } else {
        this.groupName = group.user_has_group.name
        this.groupId = group.user_has_group.id
        this.$store.dispatch('reports/getDevices', { userHasGroupId: group.user_has_group.id })
      }
    },
    setGroupsResettingDeviceAndSsp(group) {
      this.groupName = group
      this.groupId = 'all'
      this.deviceName = ''
      this.deviceId = ''
      this.ssp = ''
    },
    setDevicesAndResettingSsp() {
      this.deviceName = 'All Screens'
      this.deviceId = 'all'
      this.ssp = ''
    },
    selectDevice(device) {
      this.ssp = ''
      if (this.currentUser.user_has_role[0].role.role === 'admin') {
        this.deviceName = device.name
        this.deviceId = device.id
      } else {
        this.deviceName = device.name
        this.deviceId = device.id
      }
    },
    async getAnalytics() {
      this.spinner = true
      if (this.userRole === this.AccountTypes.USER) {
        this.ssp = 'both'
      }
      const payload = {
        group_ids: this.groupId, device_ids: this.deviceId, ssp_type: this.ssp, date_filter_type: this.dateFilter, year: this.year, ...(this.dateFilter === 'Month' && { month: this.month }),
      }
      try {
        const { analytics, deviceLists } = await this.$store.dispatch('reports/getAnalyticsAndDeviceLists', { ...payload })
        if (analytics.statusCode === 200 && deviceLists.statusCode === 200) {
          showToast('Fetched Data', 'Successfully fetch device data', 'success')
        } else if (Object.keys(analytics.data).length === 0 && deviceLists.data.length === 0) {
          showToast('Data Empty', 'Reports analytics is empty', 'warning')
          this.spinner = false
        } else {
          showToast('Data', 'Error fetching data', 'danger')
          this.spinner = false
        }
      } catch (error) {
        console.error('Error:', error)
      } finally {
        this.spinner = false
      }
    },
    setMonth(value) {
      this.month = value
    },
    setFilter(value) {
      this.dateFilter = value
    },
    setYear(value) {
      this.year = value
    },
  },
}
</script>
<style lang="scss" scoped>
.total-revenue-card{
  margin-bottom: 2px;
}
.dropdown-class{
  margin-left: 10px;
}
.dropdown-class ::v-deep
    .dropdown-menu {
    max-height: 50vh;
    overflow-y: scroll;
 }
.timestamp{
  font-family: 'Montserrat';
font-style:normal;
font-weight: 700;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 150% */
text-align: right;
color: #6E6B7B;
}
@media (max-width: 992px) {
    .dropdown-class{
      font-size: 10px !important;
      padding: 0.586rem 0 !important;
    }
  }
</style>

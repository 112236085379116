<template>
  <section>
    <div class="reports">
      <Teleport to="#header-buttons" />
      <!-- Report Stats Component-->
      <!-- <ReportStats /> -->
      <!--Network Revenue Section-->
      <NetworkRevenue />
    </div>
  </section>
</template>
<script>
import Teleport from 'vue2-teleport'
import NetworkRevenue from '@/components/ad-reporting/NetworkRevenue.vue'
// import ReportStats from '@/components/ad-reporting/ReportStats.vue'

export default {
  components: {
    Teleport,
    // ReportStats,
    NetworkRevenue,
  },
  data() {
    return {
      groups: [],
    }
  },
  methods: {
  },
}
</script>
